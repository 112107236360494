import { render, staticRenderFns } from "./menuHome.vue?vue&type=template&id=3097c65a&scoped=true&"
import script from "./menuHome.vue?vue&type=script&lang=js&"
export * from "./menuHome.vue?vue&type=script&lang=js&"
import style0 from "./menuHome.vue?vue&type=style&index=0&id=3097c65a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3097c65a",
  null
  
)

export default component.exports